import { CHECK_API } from "../actions/checkActions";
import { listApi } from "api/commonApis";
import { APIS } from "api";
import { handleLogout } from "redux/actions/auth";

const checkLogoutMiddleware = (store) => (next) => async (action) => {
  if (action.type === CHECK_API) {
    const state = store.getState();
    const accessToken = state?.auth?.data?.accessToken;
    const expiration = state?.auth?.data?.expires;
    const dispatch = store.dispatch;
    try {
      if (accessToken) {
        let tokenData;
        tokenData = await listApi(APIS.FETCH_ACCESS_TOKEN, {
          accessToken: accessToken,
        }).then((res) => {
          // set invitations
          console.log(res.data);
          return res.data[0];
        });
        // Check if the expiration time is less than or equal to the current time, if so, then logout
        console.log({ accessToken, expiration, tokenData });
        const currentTime = new Date().getTime();
        const expirationTime = new Date(expiration).getTime();
        if (currentTime >= expirationTime || tokenData.isExpired) {
          dispatch(handleLogout());
          localStorage.clear();
          sessionStorage.clear();
          // window.location.reload();
        }
      }
    } catch (error) {
      console.error("Error checking logout status:", error);
    }
  }

  return next(action);
};

export default checkLogoutMiddleware;
